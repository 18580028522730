import Layout from '@/components/layout/layout';
import style from '@/styles/dashboardPage.module.css';
import CheckList from '@/components/checkList';
import authenticatedRoute from '@/components/privateRoute';
import { useState } from 'react';
import {PlayCircle, Users, Link, Search, Play} from 'react-feather';
import { Button, Modal, ModalContent, ScrollShadow, Image, Card, CardFooter } from '@nextui-org/react';


function DashBoardPage() {
  const [showModal, setShowModal] = useState(false);

  const cards = [
    {
      icon: '/icon/facebook.svg',
      title: 'Join our +1000 members community',
      description: 'Exchange tactics about Social Selling & Sales Prospecting to get clients.',
      actionUrl: () => window.open('https://www.facebook.com/groups/298365015683028', '_blank'),
      actionText: <span className={'flex items-center '}><Users className={'mr-2'} size={20}/>Join our +1000 members community</span>
    },
    {
      icon: '/static/miniature.jpg',
      title: 'Watch the full showcase',
      description: 'By Arnaud, our CEO. He will show you how to use Breakcold to get clients.',
      actionUrl: () => setShowModal(true),
      actionText: <span className={'flex items-center '}><PlayCircle className={'mr-2'} size={20}/>Watch the showcase</span>
    },
    {
      icon: '/icon/receive-dollar.svg',
      title: '25% Commission Affiliate Program',
      description: 'Get 25% recurring commissions on the people that take a Breakcold plan.',
      actionUrl: () => window.open('https://affiliates.reflio.com/invite/breakcold', '_blank'),
      actionText: <span className={'flex items-center '}><Link className={'mr-2'} size={20}/>25% Commission Affiliate Program</span>
    }
  ];

  const blogs = [
    {
      url: 'https://docs.google.com/document/d/1wk0sV-P1Z_RKujP7W9-aEtULB4lMnGFyM_SwvW3nUEU/edit?usp=sharing',
      src: '/static/blog_1.png',
      title: 'How to get hyper qualified leads & start conversations',
      description: 'Discover how to build targeted leads list that you can use for cold emailing or or personalized social selling 😎'
    },
    {
      url: 'https://docs.google.com/document/d/1XHO68yM5YpvhUXgeXvjbjSVqMxFr22oF6w_J0oIMfow/edit?usp=sharing',
      src: '/static/blog_2.png',
      title: 'The Breakcold CRM method to nurture leads & close deals',
      description: 'Learn how use Breakcold CRM to engage your qualified prospects and turn them into paying customers 🔥'
    },
    {
      url: 'https://docs.google.com/document/d/17l-4Vs2yugo4FGnGk-WnxPaJ72HtbOBfjWX8E6Q5B1M/edit?usp=sharing',
      src: '/static/blog_3.png',
      title: 'Sales CRM automation: from 0 to pro (huge time saver)',
      description: 'Save +10 hours every week with sales CRM automation workflows via webhooks, Zapier & Pabbly 🚀'
    }
  ];

  function closeModal() {
    setShowModal(false);
  }

  return (
    <Layout releaseHeight>
      <Modal
        isOpen={showModal}
        onOpenChange={closeModal}
        size={'4xl'}
      >
        <ModalContent className={'min-w-[70%]'}>
          <iframe width='100%' height='500' src='https://www.youtube-nocookie.com/embed/S9Y5155hijQ?autoplay=1'
                  title='YouTube video player' frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen />
        </ModalContent>
      </Modal>
      <div className={'flex flex-1 flex-col justify-start p-8 space-y-10 w-full'}>
        <div className={'flex flex-col w-full overflow-auto'}>
          <span className={'text-3xl font-semibold mb-4 text-black dark:text-white'}>
            👋 Getting Started
          </span>
          <ScrollShadow
            offset={0}
            orientation='horizontal'
            className='w-full'
          >
              <CheckList />
          </ScrollShadow>
        </div>
        <div className={'flex flex-col w-full overflow-auto'}>
          <span className={'text-lg font-semibold mb-2 text-black/80 dark:text-white/80'}>
            Read this if you want to get good results, we made you simple infographics
          </span>
          <div className={'flex flex-col rounded-xl'}>
            <div className={'flex flex-row gap-3 overflow-auto w-full'}>
              {blogs.map((item, idx) => (
                <a href={item.url} key={`blog_item_${idx}`} target={'_blank'}
                   className={'bg-white dark:bg-black flex flex-col overflow-hidden rounded-2xl border-1 border-stone-200 dark:border-stone-700 transition-all hover:bg-stone-100 dark:hover:bg-stone-800'}>
                  <div className={style.head}>
                    <img src={item.src} className={style.img} />
                  </div>
                  <div className={'flex flex-col p-2'}>
                    <div className={'text-sm font-semibold text-black dark:text-white mb-1 line-clamp-2'}>
                      {item.title}
                    </div>
                    <div className={'text-xs text-black/80 dark:text-white/80 line-clamp-4'}>
                      {item.description}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className={'flex flex-col w-full'}>
            <div className={'flex w-full gap-3 justify-center items-center'}>
              <div onClick={cards[0].actionUrl} className={'flex flex-col flex-1 items-end h-[200px] cursor-pointer justify-center rounded-2xl'}>
                <Button variant={'faded'} color={'primary'} onClick={cards[0].actionUrl}>
                  {cards[0].actionText}
                </Button>
              </div>
              <Card
                isFooterBlurred
                radius="lg"
                isPressable
                isHoverable
                onPress={cards[1].actionUrl}
                className="border-none"

              >
                <Image
                  alt="Woman listing to music"
                  className="object-cover"
                  height={200}
                  src={cards[1].icon}
                  width={350}
                />
                <div className={'bg-white/10 dark:bg-black/10 absolute top-0 backdrop-blur-[2px] flex items-center justify-center left-0 w-full h-full z-10'}>
                  <div className={'-translate-y-4 text-black/80 cursor-pointer hover:scale-110 transition-all'}>
                    <PlayCircle strokeWidth={2} size={64}/>
                  </div>
                </div>

                <CardFooter className="p-0 before:bg-black/10 border-black/10 border-0 overflow-hidden absolute before:rounded-xl rounded-large bottom-0 w-[100%] shadow-small z-10">
                  <Button onClick={cards[1].actionUrl} className="ml-auto text-sm" fullWidth variant="solid" color="primary" radius="lg" size="md">
                    Watch full onboarding by co-founder
                  </Button>
                </CardFooter>
              </Card>
              <div onClick={cards[2].actionUrl} className={'flex flex-col flex-1 items-start h-[200px] cursor-pointer justify-center rounded-2xl'}>
                <Button variant={'faded'} color={'primary'} onClick={cards[2].actionUrl}>
                  {cards[2].actionText}
                </Button>
              </div>
            </div>
        </div>

      </div>
    </Layout>
  );
}


export default authenticatedRoute(DashBoardPage, { whitelist: true, active: true, noNeedSubscription: true });

