import style from '@/styles/checkList.module.css';
import { Check, ExternalLink, Eye, HelpCircle, Link } from 'react-feather';
import { useSpace } from '@/utils/useSpace';
import { useRouter } from 'next/router';
import { spaceService } from '@/services/space.service';
import useSWR from 'swr';
import useCustomToasts from '@/utils/useCustomToasts';
import Loading from '@/components/ui/loading';
import clsx from 'clsx';
import { useEffect, useState } from 'react';


const DEFAULT_CHECKLISTS = [
  {
    id: 'socials_connected',
    title: 'Connect your social media accounts',
    description: 'Go to section',
    actionType: 'section',
    href: `/accounts/connect?redirect=/`,
    number: 1
  },
  {
    id: 'chrome_extension_installed',
    title: 'Download the Chrome Extension',
    description: 'Go to link',
    actionType: 'link',
    doc: () => $crisp.push(['do', 'helpdesk:article:open', ['en', '81hxfm', 'How to install the Google Chrome Extension', 'Getting started']]),
    href: `https://chrome.google.com/webstore/detail/magic-by-breakcold-social/aleoomdhnjddjlmfocibikjdpkdpadko`,
    number: 2
  },
  {
    id: 'first_lead_tracked',
    title: 'Add & track your first lead',
    description: 'Go to section',
    actionType: 'section',
    docLabel: 'Read the tutorial',
    doc: () => $crisp.push(['do', 'helpdesk:article:open', ['en', 'fjzbic', 'How to track B2B contacts on LinkedIn & Twitter', 'Getting started']]),
    href: '/leads',
    number: 3

  },
  {
    id: 'first_post_reacted',
    title: 'Like & comment your first post',
    description: 'Go to section',
    actionType: 'section',
    doc: () => $crisp.push(['do', 'helpdesk:article:open', ['en', 'k86ffi', 'How to use the Prospecting Feed', 'Getting started']]),
    href: `/feed`,
    number: 4
  },
  {
    id: 'first_lead_piped',
    title: 'Create your first deal in the Pipeline',
    description: 'Go the the Pipeline',
    actionType: 'section',
    doc: () => $crisp.push(['do', 'helpdesk:article:open', ['en', '1uite72', 'How to use the Sales Pipeline', 'Getting started']]),
    href: '/pipeline',
    number: 5
  }
];

export default function CheckList() {
  const { id_space } = useSpace();
  const router = useRouter();
  const { setToast } = useCustomToasts();
  const [checkLists, setCheckLists] = useState(DEFAULT_CHECKLISTS);

  //API
  const { data } = useSWR((id_space) ? `/checklist` : null,
    spaceService.fetchWrapperGet
    , { revalidateOnFocus: true });


  useEffect(() => {
    if (data) {
      //order the checklist by not done first
      const orderedChecklists = [...checkLists].sort((a, b) => {
        const aDone = data[a.id]?.done;
        const bDone = data[b.id]?.done;
        return aDone === bDone ? 0 : aDone ? 1 : -1;
      });
      setCheckLists(orderedChecklists);
    }
  }, [data]);

  function handleCrisp(e, doc) {
    e.stopPropagation();
    e.preventDefault();
    doc();
  }

  function handleClick(item, data) {

    if (item?.id === 'first_lead_tracked') {
      if (!data?.done && data?.id_list) {
        router.push({ pathname: '/leads', query: { list: data?.id_list } });
        // openLead(data?.id_lead, 'update');
      } else {
        setToast({
          text: 'You have already done this step'
        });
      }
      return;
    }
    if (item?.id === 'first_lead_piped') {
      if (!data?.done && data?.id_list) {
        router.push({ pathname: '/leads', query: { list: data?.id_list } });
      } else {
        setToast({
          text: 'You have already done this step'
        });
      }
      return;
    }


    let link = item.href;
    if (!link) return;
    //if it's a link, open it in a new tab
    if (link?.startsWith('http')) {
      window.open(link, '_blank');
    } else {
      router.push(link);
    }
  }

  return (
    <div className={'flex gap-3'}>
      {checkLists?.map((item, idx) => {
        return (
          <div onClick={() => handleClick(item, data?.[item.id])} key={item.number}
               className={clsx(
                 'hover:`bg-stone`-100 transition-all flex items-center justify-start cursor-pointer p-3 border-1 border-stone-200 dark:border-stone-700 rounded-2xl bg-white dark:bg-black w-[250px] min-w-[340px]',
                 data?.[item.id]?.done && '',
                 item.disabled && 'bg-stone-100 dark:bg-stone-800'
               )}
          >
            {data && (
              <div
                className={clsx('text-lg font-semibold mr-2 rounded-full w-[40px] h-[40px]  min-w-[40px] min-h-[40px] items-center justify-center flex', (!data?.[item.id]?.done && !item.disabled) && 'bg-stone-100 dark:bg-stone-800 text-black dark:text-white', data?.[item.id]?.done && 'bg-success-100 text-success-600', item.disabled && 'bg-stone-100 dark:bg-stone-800')}>
                {(data?.[item.id]?.done && style.done) ? (<Check strokeWidth={3} size={24} />) : item.number}
              </div>
            )}
            {!data && (
              <div className={'mr-2 flex items-center justify-center bg-stone-100 dark:bg-stone-800 rounded-full w-[40px] h-[40px]  min-w-[40px] min-h-[40px]'}>
                <Loading size={'sm'} />
              </div>
            )}
            <div className={'flex flex-col'}>
              <div className={'text-sm font-medium line-clamp-1'}>
                {item.title}
              </div>
              <div className={'flex flex-row gap-2 mt-1'}>
                <div className={'flex items-center hover:text-primary text-black/80 dark:text-white/80'}>
                  <div className={'mr-1'}>
                    {item.actionType === 'section' && <Link size={14} />}
                    {item.actionType === 'link' && <ExternalLink size={14} />}
                    {item.actionType === 'video' && <Eye size={14} />}
                  </div>
                  <div className={'text-sm'}>
                    {item.actionType === 'section' && 'Go to section'}
                    {item.actionType === 'link' && 'Go to link'}
                    {item.actionType === 'video' && 'See the video'}
                  </div>
                </div>
                {item.doc && (
                  <div onClick={(e) => handleCrisp(e, item.doc)}
                       className={'flex items-center hover:text-primary text-black/80 dark:text-white/80'}>
                    <div className={'mr-1'}>
                      <HelpCircle size={14} />
                    </div>
                    <div className={'text-sm'}>
                      {item?.docLabel ?? 'Watch the tutorial'}
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>
        );
      })}
    </div>
  );
}
