import { useUser } from '@/utils/useUser';
import { useRouter } from 'next/router';
import React from "react";
import PageLoader from '@/components/pageLoader';
import { useSpace } from '@/utils/useSpace';

const authenticatedRoute = (Component = null, options = {}) => {

  function AuthenticatedRoute({...props}){
    const {user, whitelisted, userLoaded} = useUser();
    const {subscription, space} = useSpace();

    const router = useRouter();
    let extensionRedirect = localStorage.getItem('extension_redirect');


    if(!user){
      router.replace('/signin');
      return null;
    }

    if(!userLoaded){
      return <PageLoader/>
    }

    if(!whitelisted && options.whitelist){
      router.replace('/invitation');
      return null;
    }

    if(extensionRedirect)
      router.push('/other/extensionLogin')

    if(space && space?.onboardingDone && options.onboarding){
      router.replace('/');
      return null;
    }

    //THIS is managed by a modal now.
    // if((subscription?.status === 'past_due')){
    //   router.replace('/plan/due');
    //   return null;
    // }
    //
    // if((subscription?.status !== 'active' && subscription?.status !== 'trialing') && !options.noNeedSubscription){
    //   router.replace('/plan');
    //   return null;
    // }

    return <Component {...props} />

  }

  return AuthenticatedRoute;
};

export default authenticatedRoute;
